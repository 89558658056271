@media (max-width: 425px) {
  .mobile_call {
    display: block;
    z-index: 5;
    background-color: #5bd262;
    border-radius: 50%;
    position: fixed;
    padding: 25px;
    top: 80%;
    left: 70%;
    border: 4px solid #78e87e;
  }
  .mobile-call img {
    height: 30px;
    width: 30px;
  }
}