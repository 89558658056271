.container {
  max-width: 1390px;
  margin: auto;
  padding: 0px 5px;
}

.main_filter {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
.main_filter a {
  text-decoration: none;
  color: #ffffff;
}

.filter_card {
  text-shadow: 1px 1px 1px black;
  margin: 15px 12px;
  overflow: hidden;
  height: 170px;
  width: 320px;
  background: #4f66cf;
  color: #ffffff;
  border-radius: 32px;
  position: relative;
  position: relative;
  transition-duration: 0.4s;
}
.filter_card img {
  z-index: 1;
}

.filter_card:hover {
  border: 1px solid #4ac692;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.card_label {
  position: absolute;
  z-index: 2;
  top: 110px;
  margin-left: 30px;
}

.text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.card_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  position: relative;
}

.card_title::before,
.card_title::after {
  content: "";
  height: 4px;
  width: 13px;
  background-color: white;
  position: absolute;
}

.card_title::before {
  top: 6px;
  transform: rotate(45deg);
  left: 245px;
}

.card_title::after {
  top: 14px;
  left: 245px;
  transform: rotate(-45deg);
}

.corporate {
  background-color: #fc992c;
}

.festival {
  background-color: #5bd262;
}

.trampoline {
  background-color: #ACC220;
}
.trampoline .card_title {
  width: 200px;
  margin-top: -10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.promotion {
  background-color: #f8d37c;
  color: white;
}

.game {
  background-color: #E55BA0;
}
.game .card_title {
  padding-top: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.carousel {
  font-size: 20px;
  line-height: 24px;
  background-color: #F65D5C;
}
.carousel .card_title {
  margin-top: -5px;
}

.food {
  background-color: #8B5E4D;
}
.food .card_title {
  padding-top: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

@media (max-width: 768px) {
  .main_filter {
    justify-content: center;
  }
}
@media (max-width: 425px) {
  .text {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0px;
  }
  .main_filter {
    justify-content: center;
  }
  .filter_card {
    margin: 15px 0px;
  }
}