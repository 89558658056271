.container {
  max-width: 1390px;
  margin: auto;
  padding: 0px 10px;
}

.header {
  background-color: #4f66cf;
}

.nav_line {
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 70px;
}

.header_contacts {
  position: relative;
  align-items: center;
  display: flex;
}

.socials {
  margin: 0px 30px 0px 0px;
  display: flex;
}
.socials li {
  margin: 0px 7px;
}

.tel_number svg {
  width: 21px;
  height: 21px;
  margin: 5px 20px 0px 0px;
}
.tel_number a {
  text-decoration: none;
  color: white;
  font-family: "Rubik";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  background: #5bd262;
  border: 4px solid #78e87e;
  box-shadow: 0px 14px 19px rgba(51, 143, 57, 0.2);
  border-radius: 57px;
  padding: 15px 30px 15px 15px;
}

.header_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4f66cf;
  padding: 50px 20px 30px;
}

.header_back {
  cursor: pointer;
  padding-top: 10px;
}
.header_back a {
  text-align: start;
  background-color: #3f52a6;
  border-radius: 50%;
  padding: 20px 14px 5px 14px;
  height: 40px;
  width: 40px;
}

.header_link {
  background-color: #3f52a6;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}

.notification {
  opacity: 0;
  z-index: -1;
  width: 310px;
  position: absolute;
  background-color: #5bd262;
  border: 4px solid #78e87e;
  padding: 25px;
  color: #ffffff;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  box-shadow: 0px 14px 19px rgba(51, 143, 57, 0.2);
  border-radius: 24px;
  transition-duration: 1s;
  top: 100vh;
  left: calc(50% - 155px);
}

.notification._active {
  z-index: 10;
  opacity: 1;
  top: 75vh;
}

.header_title {
  text-align: center;
  margin-top: 90px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
  color: #ffffff;
}

.header_button {
  text-decoration: none;
  text-align: center;
  margin-top: 50px;
  padding-bottom: 50px;
}
.header_button a {
  border-radius: 24px;
  padding: 16px 75px;
  background-color: #ffffff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #4f66cf;
}

.buttons_img {
  display: none;
}

/************************************/
@media (max-width: 768px) {
  .tel_number a {
    font-size: 23px;
    line-height: 30px;
  }
}
@media (max-width: 425px) {
  .container {
    padding: 0px 5px;
  }
  .tel-number {
    position: absolute;
    display: none;
  }
  .header {
    padding-bottom: 15px;
  }
  .header_contacts {
    display: none;
  }
  .nav_line {
    display: none;
    position: relative;
    z-index: 2;
    justify-content: center;
  }
  .nav_line img {
    margin-bottom: 25px;
    height: 50px;
  }
  .nav_line_display {
    display: flex;
  }
  .header_title {
    margin: auto;
    line-height: 40px;
    font-size: 40px;
    max-width: 225px;
  }
  .header_button a {
    font-size: 24px;
    line-height: 32px;
  }
  .header_buttons {
    min-width: 300px;
    display: flex;
    justify-content: space-between;
    top: -20px;
    z-index: 1;
    padding-bottom: 25px;
  }
  .header_button {
    padding: 16px 20px;
    font-size: 20px;
  }
  .buttons_img {
    display: block;
  }
}
@media (max-width: 375px) {
  .header_button a {
    padding: 16px 20px;
    font-size: 20px;
  }
}